<script setup>
import Close from 'assets/icons/close.svg';
import { useSideBar } from '~/stores/sideBar';

// Global
const { toggleSideBarLayer } = useSideBar();

const closeOnBoarding = () => {
    toggleSideBarLayer(false)
}
</script>

<template>
    <div class="side-bar-onboarding">
        <div class="close-sidebar" @click="closeOnBoarding()">
            <Close />
        </div>
        <slot />
    </div>
</template>

<style lang="scss">
.side-bar-onboarding {
    @include scrollbar;

    width: 100vw;
    max-width: 500px;
    background: $sidebar-content-bg;
    box-shadow: 20px 20px 40px $sidebar-content-shadow;
    position: fixed;
    z-index: z('side-bar-onboarding');
    top: 0;
    right: 0;
    bottom: 0;
}
</style>
